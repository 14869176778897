module.exports = [{
      plugin: require('D:/a/1/s/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650},
    },{
      plugin: require('D:/a/1/s/node_modules/@weknow/gatsby-remark-twitter/gatsby-browser.js'),
      options: {"plugins":[],"debug":false},
    },{
      plugin: require('D:/a/1/s/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
